import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PhotoGallery from "../components/PhotGallery/PhotoGallery"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import BackgroundImage from "gatsby-background-image"
import SEO from "../components/SEO/SEO";
import "./photo.css"

function PhotoPage() {
  const data = useStaticQuery(graphql`
    query {
      gallery: allFile(filter: { relativeDirectory: { regex: "/a|b|c/" } }) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 350, maxHeight: 350) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      photocover: allFile(filter: { relativePath: { eq: "photo.jpg" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `)
  console.log(data, "photo data")
  let photos = []
  data.gallery &&
    data.gallery.edges.forEach(image => {
      if (image.node.childImageSharp) {
        let { full, thumb } = image.node.childImageSharp
        let imgData = {
          full,
          thumb,
          caption: "Studio Thapas",
        }
        photos.push(imgData)
      }
    })
  console.log(photos, "photos")
  let coveredges = data.photocover.edges[0]
  let coverfluid = coveredges && coveredges.node.childImageSharp.fluid

  return (
    <>
      <Navbar />
      <SEO
        title="Photo "
        description="Wedding Thapas beautiful wedding photo gallery."
      ></SEO>
      <div>
        <BackgroundImage fluid={coverfluid} alt={"Cover Picture"}>
          <div className="page-header photo-header">Photos</div>
        </BackgroundImage>

        <div className="container" style={{ marginTop: "4px" }}>
          <PhotoGallery images={photos} />
        </div>
      </div>
      <Footer />
    </>
  )
}
export default PhotoPage
